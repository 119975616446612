import React, { useState, useEffect } from "react";
import { Table, Select, Button, Modal, Form, Input, message } from "antd";
import axios from "axios";
import { BaseUrl } from "../../static/BaseUrl.js";
import LoadingComponent from "../../Components/LoadingComponent";

// const { RangePicker } = DatePicker;
const Option = Select.Option;
const ChargerDetailsScreen = () => {
  const [loading, setLoading] = useState(false);
  const [chargers, setChargers] = useState([]);
  const [locations, setLocations] = useState([]);
  const [connectorTypes, setConnectorTypes] = useState();
  const [chargersFormatedData, setChargersFormatedData] = useState([]);
  const [editSelectedCharger, setEditSelectedCharger] = useState();
  const [editChargerModalOpen, setEditChargerModalOpen] = useState();
  const [connfirmDeleteModalOpen, setConfirmDeleteModalOpen] = useState();
  const [addNewChargerModalOpen, setAddNewChargerModalOpen] = useState();
  const [updatedChargerLocation, setUpdatedChargerLocation] = useState();
  const [updatedChargerName, setUpdatedChargerName] = useState();
  const [updatedSimVendor, setUpdatedSimVendor] = useState();
  const [updatedSimNumber, setUpdatedSimNumber] = useState();

  const [updateChargePointSerialnumber, setUpdatedChargePointSerialNumber] =
    useState();
  const [updatedElectricityCharges, setupdatedElectricityCharges] = useState();
  const [updatedServicesCharges, setupdatedServicesCharges] = useState();
  const [updatedActiveStatus, setupdatedActiveStatus] = useState();
  const [updatedPowerOutput, setupdatedPowerOutput] = useState();
  const [updatedConnectorCount, setupdatedConnectorCount] = useState();
  const [updatedConnectorType, setupdatedConnectorType] = useState();
  const [updatedCalfCharges, setUpdatedCalfCharged] = useState();
  const [filterSet, setfilterSet] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState();
  const [selectedCharger, setSelectedCharger] = useState();
  const [unchangedFormatedTransactions, setUnchangedFormatedTransactions] =
    useState();

  useEffect(() => {
    setUpdatedChargerLocation(editSelectedCharger?.address);
    setUpdatedSimNumber(editSelectedCharger?.simNumber);
    setUpdatedSimVendor(editSelectedCharger?.simVendor);
    setUpdatedChargerName(editSelectedCharger?.chargerName);
    setupdatedElectricityCharges(editSelectedCharger?.electricityChargePerkWh);
    setupdatedServicesCharges(editSelectedCharger?.serviceChargePerkWh);
    setUpdatedChargePointSerialNumber(
      editSelectedCharger?.chargePointSerialNumber
    );
    setupdatedActiveStatus(editSelectedCharger?.isActive);
    setupdatedPowerOutput(editSelectedCharger?.chargerPowerOutput);
    setupdatedConnectorCount(editSelectedCharger?.connectorCount);
    setupdatedConnectorType(editSelectedCharger?.connectorType);
  }, [editSelectedCharger]);

  useEffect(() => {
    if (!selectedLocation && !selectedCharger) {
      setfilterSet(false);
      filterData();
    } else {
      setfilterSet(true);
      filterData();
    }
  }, [selectedLocation, selectedCharger]);

  const handleAddNewCharger = async () => {
    try {
      setLoading(true);
      const res = await axios.post(`${BaseUrl}/add/new/charger`, {
        token: localStorage.getItem("token"),
        chargerName: updatedChargerName,
        simNumber: updatedSimNumber,
        simVendor: updatedSimVendor,
        address: updatedChargerLocation,
        // "chargeBoxSerialNumber": setUpdatedChargePointSerialNumber,
        chargePointSerialNumber: updateChargePointSerialnumber,
        // "firmwareVersion": editSelectedCharger?.firmwareVersion,
        // "chargePointVendor": editSelectedCharger?.chargePointModel,
        // "chargePointModel": editSelectedCharger?.chargePointModel,
        chargerPowerOutput: updatedPowerOutput,
        connectorCount: updatedConnectorCount,
        connectorType: updatedConnectorType,
        electricityChargePerkWh: updatedElectricityCharges,
        serviceChargePerkWh: updatedServicesCharges,
      });
      if (res?.data?.success) {
        message.success("Charger added");
        setAddNewChargerModalOpen(false);
        getChargersDetails();
        setLoading(false);
      } else {
        message.error("Error in adding charger");
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleUpdateChargerDetails = async () => {
    try {
      setLoading(true);
      console.log(
        editSelectedCharger?.addressId,
        editSelectedCharger?.connectorTypeId
      );
      const res = await axios.post(`${BaseUrl}/update/charger/details`, {
        token: localStorage.getItem("token"),
        _id: editSelectedCharger?._id,
        chargerName: updatedChargerName || editSelectedCharger?.chargerName,
        simNumber: updatedSimNumber || editSelectedCharger?.simNumber,
        simVendor: updatedSimVendor || editSelectedCharger?.simVendor,
        address: updatedChargerLocation || editSelectedCharger?.address,
        chargeBoxSerialNumber:
          editSelectedCharger?.chargeBoxSerialNumber || "NA",
        chargePointSerialNumber:
          updateChargePointSerialnumber ||
          editSelectedCharger?.chargePointSerialNumber,
        firmwareVersion: editSelectedCharger?.firmwareVersion || "NA",
        chargePointVendor: editSelectedCharger?.chargePointModel || "NA",
        chargePointModel: editSelectedCharger?.chargePointModel || "NA",
        chargerPowerOutput:
          updatedPowerOutput || editSelectedCharger?.chargerPowerOutput,
        connectorCount:
          updatedConnectorCount || editSelectedCharger?.connectorCount,
        connectorType:
          updatedConnectorType || editSelectedCharger?.connectorType,
        electricityChargePerkWh:
          updatedElectricityCharges ||
          editSelectedCharger?.electricityChargePerkWh,
        serviceChargePerkWh:
          updatedServicesCharges || editSelectedCharger?.serviceChargePerkWh,
        calf: updatedCalfCharges || editSelectedCharger?.calf,
      });
      if (res?.data?.success) {
        message.success("Charger details updated");
        setfilterSet(false);
        setSelectedLocation(undefined);
        setSelectedCharger(undefined);
        setEditChargerModalOpen(false);
        setEditSelectedCharger("");
        getChargersDetails();
        setLoading(false);
      } else {
        setfilterSet(false);
        setSelectedLocation(undefined);
        setSelectedCharger(undefined);
        message.error("Error in updating details");
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      message.error("All Fields Required");
      setLoading(false);
    }
  };

  const handleDeleteCharger = async () => {
    try {
      setLoading(true);
      const res = await axios.post(`${BaseUrl}/delete/charger`, {
        token: localStorage.getItem("token"),
        _id: editSelectedCharger?._id,
      });
      if (res?.data?.success) {
        message.success("Charger deleted");
        setfilterSet(false);
        setSelectedLocation(undefined);
        setSelectedCharger(undefined);
        setConfirmDeleteModalOpen(false);
        getChargersDetails();
        setEditSelectedCharger("");
        setLoading(false);
      } else {
        setfilterSet(false);
        setSelectedLocation(undefined);
        setSelectedCharger(undefined);
        message.error("Error in deleting");
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const [form] = Form.useForm();
  const formLayout = "horizontal";

  const formItemLayout =
    formLayout === "horizontal"
      ? {
          labelCol: {
            span: 6,
          },
          wrapperCol: {
            span: 14,
          },
        }
      : null;

  const getChargersDetails = async () => {
    setLoading(true);
    try {
      const res = await axios.post(`${BaseUrl}/get/admin/chargers/details`, {
        token: localStorage.getItem("token"),
      });
      if (res?.data?.success) {
        // console.log(res?.data?.chargers);
        setChargers(res?.data?.chargers);
        setConnectorTypes(res?.data?.connectorTypes);
        setLocations(res?.data?.locations);
        formatChargerData(
          res?.data?.chargers,
          res?.data?.locations,
          res?.data?.connectorTypes
        );
      } else {
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getChargersDetails();
  }, []);

  const formatChargerData = (chargers, locations, connectorTypes) => {
    setLoading(true);
    try {
      let array = chargers;
      array?.map((charger) => {
        locations?.map((location) => {
          if (charger?.address === location?._id) {
            charger.addressString = location?.address;
          }
        });
        connectorTypes?.map((connector) => {
          if (charger?.connectorType === connector?._id) {
            charger.connectorTypeString = connector?.name;
          }
        });
        charger.isActive = charger?.isActive.toString();
      });
      setLoading(false);
      setUnchangedFormatedTransactions(array);
      return setChargersFormatedData(array);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const filterData = async () => {
    try {
      setLoading(true);
      if (selectedLocation && selectedCharger) {
        setfilterSet(true);
        const array = unchangedFormatedTransactions;
        const resultArray = [];
        array?.map((charger) => {
          if (
            charger?.chargerName === selectedCharger &&
            charger?.address === selectedLocation
          ) {
            resultArray.push(charger);
          }
        });
        setChargersFormatedData(resultArray);
        setLoading(false);
      } else if (selectedLocation) {
        // If only location is selected
        setfilterSet(true);
        const array = unchangedFormatedTransactions;
        const resultArray = [];
        array?.map((charger) => {
          if (charger?.address?.toString() === selectedLocation) {
            resultArray.push(charger);
          }
        });
        setChargersFormatedData(resultArray);
        setLoading(false);
      } else if (selectedCharger) {
        // If only charger is selected
        setfilterSet(true);
        const array = unchangedFormatedTransactions;
        const resultArray = [];
        array?.map((charger) => {
          if (charger?.chargerName === selectedCharger) {
            resultArray.push(charger);
          }
        });
        setChargersFormatedData(resultArray);
        setLoading(false);
      } else {
        setChargersFormatedData(unchangedFormatedTransactions);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const columns = [
    {
      title: "Address",
      dataIndex: "addressString",
      fixed: "left",
      width: 200,
    },
    {
      title: "Charge Name",
      dataIndex: `chargerName`,
      fixed: "left",
      width: 180,
    },
    {
      title: "Electricity Charge",
      dataIndex: `electricityChargePerkWh`,
      width: 110,
    },
    {
      title: "Service Charge",
      dataIndex: `serviceChargePerkWh`,
      width: 110,
    },
    {
      title: "Calf Charge",
      dataIndex: `calf`,
      width: 100,
    },
    {
      title: "Charge Point Vendor",
      dataIndex: `chargePointVendor`,
      width: 180,
    },
    {
      title: "Charge Point Serial Number",
      dataIndex: "chargePointSerialNumber",
      width: 180,
    },
    {
      title: "Sim Vendor",
      dataIndex: "simVendor",
      width: 90,
    },
    {
      title: "Sim Number",
      dataIndex: "simNumber",
      width: 180,
    },

    {
      title: "Charge Point Model",
      dataIndex: "chargePointModel",
      width: 150,
    },

    {
      title: "Charge Box Serial Number",
      dataIndex: "chargeBoxSerialNumber",
      width: 180,
    },
    {
      title: "Firmware Version",
      dataIndex: "firmwareVersion",
      width: 150,
    },

    {
      title: "Active",
      dataIndex: `isActive`,
      width: 110,
    },
    {
      title: "Power Output",
      dataIndex: "chargerPowerOutput",
      width: 110,
    },
    {
      title: "Connector Count",
      dataIndex: "connectorCount",
      width: 100,
    },
    {
      title: "Connector Type",
      dataIndex: "connectorTypeString",
      width: 110,
    },
    {
      title: "Action",
      key: "operation",
      fixed: "right",
      width: 100,
      render: (text, record) => (
        <a
          onClick={() => {
            setEditSelectedCharger(record);
            setEditChargerModalOpen(true);
          }}
          className="ml-4"
          href="javascript:;"
        >
          <i style={{ color: "blue" }} class="fa-regular fa-pen-to-square"></i>
        </a>
      ),
    },
  ];

  return loading ? (
    <LoadingComponent />
  ) : (
    <div>
      {/* Confirm delete Location Modal */}
      <Modal
        // title="Confirm"
        centered
        open={connfirmDeleteModalOpen}
        onCancel={() => {
          setConfirmDeleteModalOpen(false);
          setEditSelectedCharger("");
        }}
        footer={[
          null,
          <Button
            onClick={() => {
              setConfirmDeleteModalOpen(false);
              setEditSelectedCharger("");
            }}
          >
            Cancel
          </Button>,
          <Button
            danger
            onClick={() => {
              setConfirmDeleteModalOpen(false);
              handleDeleteCharger();
            }}
          >
            Delete
          </Button>,
        ]}
      >
        <p className=" font-bold text-center p-4 pt-8">
          Are you sure to delete{" "}
          {editSelectedCharger && editSelectedCharger?.chargerName}
        </p>
        {/* <p className="font-bold text-center p-4">
          {selectedLocation && selectedLocation?.captiveCode}
        </p> */}
      </Modal>

      {/* Add New Charger */}
      <Modal
        title="Add new charger"
        centered
        open={addNewChargerModalOpen}
        onCancel={() => {
          setAddNewChargerModalOpen(false);
          setEditSelectedCharger();
        }}
        footer={[null]}
      >
        <Form
          className="mt-7"
          {...formItemLayout}
          layout={formLayout}
          style={{ maxWidth: formLayout === "inline" ? "none" : 600 }}
        >
          <Form.Item label="Address">
            <Select
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              placeholder={editSelectedCharger?.address}
              onChange={(value) => setUpdatedChargerLocation(value)}
            >
              {locations?.map((location) => {
                return (
                  <option value={location?._id}>{location?.address}</option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item label="Charger Name">
            <Input
              onChange={(e) => setUpdatedChargerName(e.target.value)}
              placeholder={editSelectedCharger?.chargerName}
            />
          </Form.Item>
          <Form.Item label="Sim Vendor">
            <Input
              onChange={(e) => setUpdatedSimVendor(e.target.value)}
              placeholder={editSelectedCharger?.simVendor}
            />
          </Form.Item>
          <Form.Item label="Sim Number">
            <Input
              onChange={(e) => setUpdatedSimNumber(e.target.value)}
              placeholder={editSelectedCharger?.simNumber}
            />
          </Form.Item>
          <Form.Item label="Electricity Ch.">
            <Input
              onChange={(e) => setupdatedElectricityCharges(e.target.value)}
              placeholder={editSelectedCharger?.electricityChargePerkWh}
            />
          </Form.Item>
          <Form.Item label="Services Ch.">
            <Input
              onChange={(e) => setupdatedServicesCharges(e.target.value)}
              placeholder={editSelectedCharger?.serviceChargePerkWh}
            />
          </Form.Item>
          <Form.Item label="Calf Ch.">
            <Input
              onChange={(e) => setUpdatedCalfCharged(e.target.value)}
              placeholder={editSelectedCharger?.calf}
            />
          </Form.Item>
          <Form.Item label="Serial No.">
            <Input
              onChange={(e) =>
                setUpdatedChargePointSerialNumber(e.target.value)
              }
              placeholder={editSelectedCharger?.chargePointSerialNumber}
            />
          </Form.Item>
          <Form.Item label="Connector Count">
            <Input
              maxLength={1}
              onChange={(e) => setupdatedConnectorCount(e.target.value)}
              placeholder={editSelectedCharger?.chargerPowerOutput}
            />
          </Form.Item>
          <Form.Item label="Power Output">
            <Input
              onChange={(e) => setupdatedPowerOutput(e.target.value)}
              placeholder={editSelectedCharger?.chargerPowerOutput}
            />
          </Form.Item>
          <Form.Item label="Connector Type">
            <Select
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              placeholder={editSelectedCharger?.connectorType}
              onChange={(value) => setupdatedConnectorType(value)}
            >
              {connectorTypes?.map((connectorType) => {
                return (
                  <option value={connectorType?._id}>
                    {connectorType?.name}
                  </option>
                );
              })}
            </Select>
          </Form.Item>
        </Form>
        <div className="mt-16">
          <Button
            onClick={() => handleAddNewCharger()}
            style={{ position: "absolute", right: 20, bottom: 15 }}
            type="default"
          >
            Add
          </Button>
          <Button
            onClick={() => {
              setAddNewChargerModalOpen(false);
              setEditSelectedCharger();
            }}
            style={{ position: "absolute", right: 110, bottom: 15 }}
            type="dashed"
          >
            Cancel
          </Button>
        </div>{" "}
      </Modal>

      {/* Edit Charger Details */}
      <Modal
        title="Edit Charger Details"
        centered
        open={editChargerModalOpen}
        onCancel={() => {
          setEditChargerModalOpen(false);
          setEditSelectedCharger();
        }}
        footer={[null]}
      >
        <Form
          className="mt-7"
          {...formItemLayout}
          layout={formLayout}
          form={form}
          style={{ maxWidth: formLayout === "inline" ? "none" : 600 }}
        >
          <Form.Item label="Address">
            <Select
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              placeholder={editSelectedCharger?.address}
              onChange={(value) => setUpdatedChargerLocation(value)}
            >
              {locations?.map((location) => {
                return (
                  <option value={location?._id}>{location?.address}</option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item label="Charger Name">
            <Input
              onChange={(e) => setUpdatedChargerName(e.target.value)}
              placeholder={editSelectedCharger?.chargerName}
            />
          </Form.Item>
          <Form.Item label="Sim Vendor">
            <Input
              onChange={(e) => setUpdatedSimVendor(e.target.value)}
              placeholder={editSelectedCharger?.simVendor}
            />
          </Form.Item>
          <Form.Item label="Sim Number">
            <Input
              onChange={(e) => setUpdatedSimNumber(e.target.value)}
              placeholder={editSelectedCharger?.simNumber}
            />
          </Form.Item>
          <Form.Item label="Electricity Ch.">
            <Input
              onChange={(e) => setupdatedElectricityCharges(e.target.value)}
              placeholder={editSelectedCharger?.electricityChargePerkWh}
            />
          </Form.Item>
          <Form.Item label="Services Ch.">
            <Input
              onChange={(e) => setupdatedServicesCharges(e.target.value)}
              placeholder={editSelectedCharger?.serviceChargePerkWh}
            />
          </Form.Item>
          <Form.Item label="Calf Ch.">
            <Input
              onChange={(e) => setUpdatedCalfCharged(e.target.value)}
              placeholder={editSelectedCharger?.calf}
            />
          </Form.Item>
          <Form.Item label="Serial No.">
            <Input
              onChange={(e) =>
                setUpdatedChargePointSerialNumber(e.target.value)
              }
              placeholder={editSelectedCharger?.chargePointSerialNumber}
            />
          </Form.Item>
          <Form.Item label="Connector Count">
            <Input
              maxLength={1}
              onChange={(e) => setupdatedConnectorCount(e.target.value)}
              placeholder={editSelectedCharger?.connectorCount}
            />
          </Form.Item>
          <Form.Item label="Power Output">
            <Input
              onChange={(e) => setupdatedPowerOutput(e.target.value)}
              placeholder={editSelectedCharger?.chargerPowerOutput}
            />
          </Form.Item>
          <Form.Item label="Connector Type">
            <Select
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              placeholder={editSelectedCharger?.connectorType}
              onChange={(value) => setupdatedConnectorType(value)}
            >
              {connectorTypes?.map((connectorType) => {
                return (
                  <option value={connectorType?._id}>
                    {connectorType?.name}
                  </option>
                );
              })}
            </Select>
          </Form.Item>
        </Form>
        <div className="mt-16">
          <Button
            onClick={() => handleUpdateChargerDetails()}
            style={{ position: "absolute", right: 20, bottom: 15 }}
            type="default"
          >
            Update
          </Button>
          <Button
            onClick={() => {
              setEditChargerModalOpen(false);
              setEditSelectedCharger();
            }}
            style={{ position: "absolute", right: 110, bottom: 15 }}
            type="dashed"
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              setEditChargerModalOpen(false);
              setConfirmDeleteModalOpen(true);
            }}
            style={{ position: "absolute", right: 200, bottom: 15 }}
            danger
          >
            Delete
          </Button>
        </div>{" "}
      </Modal>

      {/* Heading */}
      <div className="w-full flex flex-col sm:flex-row justify-center sm:justify-start">
        <p style={{ marginTop: 4 }} className="font-bold text-lg text-center">
          Charger Details
        </p>
        <span className="ml-0 sm:ml-4 flex justify-center items-center">
          <a
            onClick={() => setAddNewChargerModalOpen(true)}
            className="text-center pr-2 pl-2 pt-2 pb-2 rounded-md  text-slate-500"
          >
            Add New Charger
          </a>
        </span>
      </div>

      {/* Filter Data */}
      <div className="mt-10 flex flex-col flex-wrap sm:flex-row justify-start">
        <div className="flex">
          <p style={{ marginTop: 2 }}>Address</p>
          <Select
            className="ml-4"
            showSearch
            style={{ width: 400 }}
            placeholder="Select adress"
            optionFilterProp="children"
            onChange={(value) => setSelectedLocation(value)}
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            {locations?.map((location) => {
              return (
                <Option value={location?._id?.toString()}>
                  {location?.address}
                </Option>
              );
            })}
          </Select>
        </div>

        {/* Charger */}
        <div className="flex ml-5">
          <p style={{ marginTop: 2 }}>Charger</p>
          <Select
            className="ml-4"
            showSearch
            style={{ width: 200 }}
            placeholder="Select a charger"
            optionFilterProp="children"
            onChange={(value) => setSelectedCharger(value)}
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            {unchangedFormatedTransactions?.map((charger) => {
              return (
                <Option value={charger?.chargerName}>
                  {charger?.chargerName}
                </Option>
              );
            })}
          </Select>
        </div>
      </div>

      <div className="flex justify-between mt-3">
        <div className="flex">
          <a
            style={{ display: filterSet ? "block" : "none" }}
            className="text-center pr-2 pl-2 pt-1 pb-1 border rounded-md "
          >
            Filters :
          </a>
          <div className="ml-4 mt-1 flex">
            {filterSet && selectedLocation ? (
              <p
                className="mr-2"
                style={{
                  fontSize: 13,
                  backgroundColor: "#ADD8E6",
                  paddingTop: 4,
                  paddingBottom: 4,
                  borderRadius: 10,
                  paddingRight: 10,
                  paddingLeft: 10,
                  color: "black",
                }}
              >
                Location Id : {selectedLocation}
                <a className="cursor-pointer pl-2">
                  <i
                    onClick={() => {
                      setSelectedLocation(undefined);
                      filterData();
                    }}
                    style={{ color: "black", marginTop: 1 }}
                    className="fa-solid fa-xmark"
                  ></i>
                </a>
              </p>
            ) : (
              ""
            )}

            {filterSet && selectedCharger ? (
              <p
                className="mr-2"
                style={{
                  fontSize: 13,
                  backgroundColor: "#ADD8E6",
                  paddingTop: 4,
                  paddingBottom: 4,
                  borderRadius: 10,
                  paddingRight: 10,
                  paddingLeft: 10,
                  color: "black",
                }}
              >
                Charger : {selectedCharger}
                <a className="cursor-pointer pl-2">
                  <i
                    onClick={() => {
                      setSelectedCharger(undefined);
                      filterData();
                    }}
                    style={{ color: "black", marginTop: 1 }}
                    className="fa-solid fa-xmark"
                  ></i>
                </a>
              </p>
            ) : (
              ""
            )}
          </div>
        </div>
        {filterSet ? (
          <a
            onClick={() => {
              setfilterSet(false);
              setSelectedLocation(undefined);
              setSelectedCharger(undefined);
            }}
            className="mr-2 mt-2"
            style={{ fontSize: 13 }}
          >
            Clear Filters
          </a>
        ) : (
          ""
        )}
      </div>

      {/* Showing Chargers */}
      <div className="mt-4 overflow-auto">
        <Table
          columns={columns}
          dataSource={chargersFormatedData}
          scroll={{
            y: 600,
          }}
        />
      </div>
    </div>
  );
};

export default ChargerDetailsScreen;
