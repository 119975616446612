import axios from "axios";
import React, { useState, useEffect } from "react";
import { BaseUrl } from "../../static/BaseUrl.js";
import { Modal, message } from "antd";
import LoadingComponent from "../../Components/LoadingComponent.js";

import { Select } from "antd";
const Option = Select.Option;

const EvManufacturersScreen = () => {
  const [photo, setPhoto] = useState();
  const [manufacturerName, setManufacturerName] = useState();
  const [loadingScreen, setLoadingScreen] = useState(true);

  const [manufacturers, setManufacturers] = useState();

  const [carName, setCarName] = useState();

  const [cars, setCars] = useState();

  const [addCarModalOpen, setAddCarModalOpen] = useState(false);
  const [selectedCar, setSelectedCar] = useState();
  const [updatedBatteryCapacities, setUpdatedBatteryCapacities] = useState([]);
  const [isEditCarModelOpen, setEditCarModelOpen] = useState(false);
  const [updatedName, setUpdatedName] = useState();

  const [deleteCarModalOpen, setDeleteCarModalOpen] = useState(false);

  const [profilePicData, setProfilePicData] = useState();
  const [profilepicLink, setProfilePicLink] = useState();

  const handleAddCapacity = (newCapacity) => {
    if (newCapacity && !updatedBatteryCapacities.includes(newCapacity)) {
      setUpdatedBatteryCapacities([...updatedBatteryCapacities, newCapacity]);
    }
  };

  useEffect(() => {
    selectedCar && setUpdatedBatteryCapacities(selectedCar?.batteryCapacity);
  }, [selectedCar]);

  const handleDeleteCapacity = (capacity) => {
    const filteredCapacities = updatedBatteryCapacities.filter(
      (item) => item !== capacity
    );
    setUpdatedBatteryCapacities(filteredCapacities);
  };

  const getCars = async () => {
    try {
      const res = await axios.post(`${BaseUrl}/get/all/cars`, {
        token: localStorage.getItem("token"),
      });
      if (res?.data?.success) {
        setCars(res?.data?.cars);
        setLoadingScreen(false);
      } else {
        setLoadingScreen(false);
      }
    } catch (error) {
      console.log(error);
      setLoadingScreen(false);
    }
  };

  const getManufacturers = async () => {
    try {
      const res = await axios.post(`${BaseUrl}/get/all/manufacturers`, {
        token: localStorage.getItem("token"),
      });
      if (res?.data?.success) {
        setManufacturers(res?.data?.manufacturers);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCars();
    getManufacturers();
  }, []);

  const handleAddNewCar = async (e) => {
    e.preventDefault();
    try {
      if (!manufacturerName || !carName || !profilePicData) {
        return message.warning("All field required");
      }

      let images3Url = profilePicData;

      if (profilePicData) {
        // Step 2: Get the signed URL for uploading the profile picture
        const res = await axios.post(
          `${BaseUrl}/s3/generate/upload/url/for/ev`,
          {
            token: localStorage?.getItem("token"),
          }
        );

        const url = res?.data?.url;
        if (!url) {
          throw new Error("Failed to get signed URL for profile pic.");
        }

        // Step 3: Upload the profile picture to S3
        const response = await fetch(url, {
          method: "PUT",
          headers: {
            "Content-Type": profilePicData?.type || "application/octet-stream",
          },
          body: profilePicData,
        });

        if (!response.ok) {
          console.error("Profile picture upload failed:", response);
          throw new Error("Failed to upload profile picture.");
        }

        // Step 4: Get the uploaded image URL without query string (to be used in the backend)
        images3Url = url.split("?")[0];
      }

      const ManufacturerData = new FormData();
      ManufacturerData.append("name", carName);
      ManufacturerData.append("manufacturer", manufacturerName);
      ManufacturerData.append("url", images3Url);

      const res = await axios.post(`${BaseUrl}/add/new/car`, {
        token: localStorage?.getItem("token"),
        ManufacturerData,
      });
      if (res?.data?.success) {
        setAddCarModalOpen(false);
        getCars();
        message.success(res?.data?.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteCar = async () => {
    try {
      const res = await axios.post(`${BaseUrl}/delete/car`, {
        token: localStorage.getItem("token"),
        carId: selectedCar?._id,
      });
      if (res?.data?.success) {
        setEditCarModelOpen(false);
        setDeleteCarModalOpen(false);
        getCars();
        message.success(res?.data?.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleUpdateCarDetails = async (e) => {
    e.preventDefault();
    const payload = {
      ...selectedCar,
      batteryCapacity: updatedBatteryCapacities,
      name: updatedName || selectedCar?.name,
    };

    const res = await axios.post(`${BaseUrl}/update/ev/battery/capacities`, {
      token: localStorage?.getItem("token"),
      evData: payload,
    });

    if (res?.data?.success) {
      setEditCarModelOpen(false);
      message.success(res?.data?.message);
      getCars();
    } else {
      message.error(res?.data?.message);
    }
  };

  // Handle File Upload
  const handleFileUpload = (e) => {
    const files = Array.from(e.target.files);

    files.forEach((file) => {
      if (file.size > 1 * 1024 * 1024) {
        alert(`File size exceeds ${1} MB limit.`);
        return;
      }

      // Handle images
      if (file.type.startsWith("image/")) {
        setProfilePicData(file);
        setProfilePicLink(URL.createObjectURL(file));
      }
    });
  };

  return loadingScreen ? (
    <LoadingComponent />
  ) : (
    <>
      <Modal
        title="Add Ev Car"
        centered
        open={addCarModalOpen}
        onCancel={() => setAddCarModalOpen(false)}
        footer={[
          null,
          <button
            style={{
              backgroundColor: "#001529",
              color: "white",
              padding: 10,
              borderRadius: 3,
            }}
            className="btn btn-primary"
            onClick={(e) => handleAddNewCar(e)}
          >
            Submit
          </button>,
        ]}
      >
        <div className="mb-4">
          <label className="btn btn-outline-secondary col-md-12">
            Select manufacturer
            <Select
              className="ml-4"
              showSearch
              style={{ width: 300 }}
              placeholder="Select manufacturer"
              optionFilterProp="children"
              onChange={(value) => setManufacturerName(value)}
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {manufacturers &&
                manufacturers?.map((manufacturer) => {
                  return (
                    <Option style={{ fontSize: 12 }} value={manufacturer?.name}>
                      {manufacturer?.name}
                    </Option>
                  );
                })}
            </Select>
          </label>
        </div>
        <label className="btn btn-outline-secondary col-md-12 mt-12">
          Car name
          <input
            type="text"
            required
            style={{
              borderWidth: 1,
              borderColor: "grey",
              borderRadius: 4,
              marginLeft: 84,
              padding: 4,
            }}
            onChange={(e) => setCarName(e.target.value)}
            // hidden
          />
        </label>

        <div className="mb-3">
          <label
            className="btn btn-outline-secondary col-md-12"
            style={{ textAlign: "center" }}
          >
            {/* {photo ? photo.name : "Upload Photo"} */}
            Upload Photo
            <input
              type="file"
              name="photo"
              accept="image/*"
              onChange={handleFileUpload}
              style={{ marginLeft: 60, marginTop: 30 }}
              // hidden
            />
          </label>
        </div>
        <div className="mb-3">
          {profilePicData && (
            <div className="text-center justify-center items-center ml-28">
              <img
                src={profilepicLink}
                alt="product_photo"
                height={"200px"}
                className="img img-responsive"
              />
            </div>
          )}
        </div>
      </Modal>

      <Modal
        title="Delete Ev Car"
        centered
        open={deleteCarModalOpen}
        onCancel={() => setDeleteCarModalOpen(false)}
        footer={[
          null,
          <button
            style={{
              // backgroundColor: "#001529",
              // color: "white",
              padding: 10,
              borderRadius: 3,
              marginRight: 10,
              borderWidth: 1,
            }}
            className="btn btn-primary"
            onClick={() => setDeleteCarModalOpen(false)}
          >
            Cancel
          </button>,
          <button
            style={{
              backgroundColor: "#001529",
              color: "white",
              padding: 10,
              borderRadius: 3,
            }}
            className="btn btn-primary"
            onClick={() => handleDeleteCar()}
          >
            Delete
          </button>,
        ]}
      >
        <p>Are you sure to delete {selectedCar?.name} car ?</p>
      </Modal>

      <Modal
        title="Edit EV Details"
        centered
        open={isEditCarModelOpen}
        onCancel={() => setEditCarModelOpen(false)}
        footer={[
          null,
          <button
            style={{
              padding: 10,
              borderRadius: 3,
            }}
            className="btn-secondary mx-5 mt-5 border"
            onClick={(e) => setDeleteCarModalOpen(true)}
          >
            Delete
          </button>,
          <button
            style={{
              padding: 10,
              borderRadius: 3,
            }}
            className="btn btn-primary bg-blue-200"
            onClick={(e) => handleUpdateCarDetails(e)}
          >
            Update
          </button>,
        ]}
      >
        <div>
          <div className="relative mx-5 rounded-xl">
            <img
              alt={selectedCar?.name}
              src={`${selectedCar?.uri}`}
              style={{ height: 120, width: 130 }}
            />

            <div className="w-full flex mt-5 justify-center bg-blue-100 py-2 rounded">
              <p className="mt-2 mr-3">Ev name : </p>
              <input
                type="text"
                defaultValue={selectedCar?.name}
                placeholder="Enter EV Name"
                className="border rounded p-2 mr-2"
                onChange={(e) => setUpdatedName(e.target.value)}
              />
            </div>

            <div className="w-full">
              <p className="text-left mt-4 mb-2">Battery Capacities</p>
            </div>
            <div className="flex flex-wrap max-w-full">
              {updatedBatteryCapacities.map((capacity) => (
                <div
                  key={capacity}
                  className="border-0 px-2 py-1 mx-2 my-2 rounded-lg bg-blue-100 flex items-center"
                >
                  <p className="mr-2">{capacity} kW</p>
                  <button
                    onClick={() => handleDeleteCapacity(capacity)}
                    className="text-red-500 font-bold"
                  >
                    ✕
                  </button>
                </div>
              ))}
            </div>
            <div className="mt-4">
              <input
                type="text"
                placeholder="Add new capacity (kW)"
                className="border rounded p-2 mr-2"
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleAddCapacity(e.target.value);
                    e.target.value = ""; // Clear input
                  }
                }}
              />
              <button
                className="btn btn-primary"
                onClick={() => {
                  const input = document.querySelector("input");
                  if (input?.value) {
                    handleAddCapacity(input.value);
                    input.value = ""; // Clear input
                  }
                }}
              >
                Add
              </button>
            </div>
          </div>
        </div>
      </Modal>

      <div className="w-full flex flex-col sm:flex-row justify-center sm:justify-start">
        <p style={{ marginTop: 4 }} className="font-bold text-lg text-center">
          Ev Cars
        </p>
        <span className="ml-0 sm:ml-4 flex justify-center items-center">
          <a
            onClick={() => setAddCarModalOpen(true)}
            className="text-center pr-2 pl-2 pt-2 pb-2 rounded-md  text-slate-500"
          >
            Add car
          </a>
        </span>
      </div>

      {/* Show All Manufacturers */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          flexWrap: "wrap",
          marginTop: 40,
        }}
      >
        {cars &&
          cars?.map((manufacturer, index) => {
            return (
              <div className="relative mx-5 px-20 py-10 my-10 rounded-xl shadow-xl md:w-[30%]">
                <i
                  onClick={() => {
                    setSelectedCar(manufacturer);
                    setEditCarModelOpen(true);
                  }}
                  style={{ color: "gray" }}
                  class="fa-regular fa-pen-to-square absolute right-5"
                ></i>
                <img
                  alt={index}
                  src={`${manufacturer?.uri}`}
                  style={{ height: 120, width: 130 }}
                />
                <p className="text-center text-lg font-semibold">
                  {manufacturer?.manufacturer + " "}
                  {manufacturer?.name}
                </p>
                <div className="w-full">
                  <p className="text-left mt-4 mb-2">Battery Capacities</p>
                </div>
                <div className="flex flex-wrap max-w-full">
                  {manufacturer?.batteryCapacity?.map((capacity) => {
                    return (
                      <div
                        key={capacity}
                        className="border-0 px-2 py-1 mx-2 my-2 rounded-lg bg-blue-100"
                      >
                        <p>{capacity} kW</p>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
      </div>
    </>
  );
};

export default EvManufacturersScreen;
